import axios from "axios";

//const baseURL = "http://localhost/clinica_sos_back-2023/api";
const baseURL = "https://api.fairdent.grupo-sos.com/api";

export const getDatapacienteByDni = async (dataDni) => {
  return await axios
    .post(`${baseURL}/getDataClientById`, {
      dataDni,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const saveFingerPrintCliente = async (id_cliente, imagenbase64) => {
  return await axios
    .post(`${baseURL}/saveFingerPrintCliente`, {
      id_cliente,
      imagenbase64,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};
