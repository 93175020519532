const Fingerprint = window.Fingerprint;

const FingerprintSdk = (function () {
  function FingerprintSdk() {
    this.sdk = new Fingerprint.WebApi();

    this.sdk.onSamplesAcquired = function (s) {
      console.log("onSampleAcquired", JSON.parse(s.samples));
      samplesAcquired(s);
    };
  }

  FingerprintSdk.prototype.getDeviceList = function () {
    return this.sdk.enumerateDevices();
  };

  FingerprintSdk.prototype.startCapture = function (Spinner) {
    return this.sdk.startAcquisition(Fingerprint.SampleFormat.PngImage).then(
      function (response) {
        console.log(response, "Capturando huella");
        let spinner = document.getElementById("imagediv");
        spinner.innerHTML =
          // "<p class='spinner1'> cargandoooo .... </p>";
          "<div class='lds-spinner'><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>";
        return {
          color: "success",
          mensaje:
            "<p>Capturando huella , por favor coloque su dedo sobre el dispositivo ... </p>",
          success: true,
        };
      },
      function (error) {
        console.log(error, "Error al comenzar la captura de huella");
        return {
          color: "danger",
          mensaje:
            "<p>Error al comenzar la captura de huella,reinicie el sistema por favor <a href='javascript:location.reload()'>(Reiniciar)</a></p>",
          error: error,
          success: false,
        };
      }
    );
  };

  FingerprintSdk.prototype.stopCapture = function () {
    return this.sdk.stopAcquisition().then(
      function () {
        console.log("Captura de huella detenida");
        return {
          color: "success",
          mensaje: "<p>Captura de huella detenida</p>",
          success: true,
        };
      },
      function (error) {
        console.log("Error al detener la captura de huella");
        return {
          color: "danger",
          mensaje:
            "<p>Error al detener la captura de huella, reinicie el sistema por favor <a href='javascript:location.reload()'>(Reiniciar)</a></p>",
          error: error,
          success: false,
        };
      }
    );
  };

  return FingerprintSdk;
})();

function samplesAcquired(s) {
  // if(currentFormat === Fingerprint.SampleFormat.PngImage){
  // // If sample acquired format is PNG- perform following call on object received
  // // Get samples from the object - get 0th element of samples as base 64 encoded PNG image
  console.log("huella datos:", s);
  localStorage.setItem("imageSrc", "");
  let samples = JSON.parse(s.samples);
  localStorage.setItem(
    "imageSrc",
    "data:image/png;base64," + Fingerprint.b64UrlTo64(samples[0])
  );
  let vDiv = document.getElementById("imagediv");
  vDiv.innerHTML = "";
  let image = document.createElement("img");
  image.id = "image";
  image.style.width = "100%";
  image.src = localStorage.getItem("imageSrc");
  vDiv.appendChild(image);
  // }
}

module.exports = { FingerprintSdk, Fingerprint };
