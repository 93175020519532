import React, { Component } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { FingerprintSdk } from "./fingerprint_reader/api/sdk_mod";
import "./app.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import Swal from "sweetalert2";
import {
  getDatapacienteByDni,
  saveFingerPrintCliente,
} from "./api/ServiciosApiFairdent";

class App extends Component {
  state = {
    visible: false,
    loadindFingerprint: false,
    id_cliente: 0,
    dni: "",
    nombres: "",
    apellidos: "",
    loadingData: false,
  };

  componentDidMount() {
    const Fingerprint = new FingerprintSdk();
    this.setState({ Fingerprint }, () => {
      this.state.Fingerprint.getDeviceList().then(
        (devices) => {
          console.log("iniciando - lista dispositivos", devices);
          this.setState({ deviceId: devices[0] });
        },
        (error) => console.log(error)
      );
    });
  }

  clearImage = async () => {
    let vDiv = document.getElementById("imagediv");
    vDiv.innerHTML = "";
    localStorage.setItem("imageSrc", "");
    console.log("huella eliminada");
    await this.setState({
      visible: true,
      alertColor: "success",
      //alertMsg: "Huella eliminada!",
      //loadindFingerprint: false,
    });
    document.getElementById("alertMsg").innerHTML = "<p>Huella eliminada</p>";
  };

  startCapturing = () => {
    this.state.Fingerprint.startCapture()
      .then((res) => {
        console.log("respuesta", res);
        this.setState({
          visible: true,
          alertColor: res.color,
          //alertMsg: res.mensaje,
          //loadindFingerprint: true,
        });
        document.getElementById("alertMsg").innerHTML = res.mensaje;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  stopCapturing = () => {
    this.state.Fingerprint.stopCapture()
      .then((res) => {
        console.log("respuesta", res);
        this.setState({
          visible: true,
          alertColor: res.color,
          //alertMsg: res.mensaje,
          //loadindFingerprint: false,
        });
        document.getElementById("alertMsg").innerHTML = res.mensaje;
        document.getElementById("imagediv").innerHTML = "";
      })
      .catch((error) => {
        console.error(error);
      });
  };

  getInfo = () => {
    this.state.Fingerprint.getDeviceList().then(
      (devices) => {
        this.setState({ deviceId: devices[0] });
        console.log("devices", devices);
        var valor = "";
        if (devices && devices.length !== 0) {
          valor = "<p>Lista de dispositivos conectados:</p>";
          devices.forEach((element, index) => {
            var list = devices[index];
            valor += "<li>" + list + "</li>";
          });
        } else {
          valor = "No hay dispositivos conectados ...";
        }

        document.getElementById("devices-list").innerHTML = valor;
      },
      (error) => console.log(error)
    );
  };

  showState = () => {
    console.log(this.state);
  };

  verifyFingerPrintClient = async () => {
    if (
      localStorage.getItem("imageSrc") === "" ||
      localStorage.getItem("imageSrc") === null ||
      document.getElementById("imagediv").innerHTML === ""
    ) {
      //alert("No image to download");
      await this.setState({
        visible: true,
        alertColor: "warning",
        //alertMsg: res.mensaje,
        //loadindFingerprint: false,
      });
      document.getElementById("alertMsg").innerHTML =
        "<p>No hay imagen almacenada, verifique si capturó su huella digital en el sistema</p>";
    } else {
      //alert(localStorage.getItem("imageSrc"));
      this.saveFingerPrintClient();
    }
  };

  saveFingerPrintClient = async () => {
    //const dni = this.state.dni;
    const nombres = this.state.nombres;
    const id_cliente = this.state.id_cliente;
    //const apellidos = this.state.apellidos;
    const imagenbase64 = localStorage.getItem("imageSrc");
    if (id_cliente !== 0 && nombres !== "") {
      console.log(localStorage.getItem("imageSrc"));
      console.log("guardando datos");
      console.log(id_cliente);
      const res = await saveFingerPrintCliente(id_cliente, imagenbase64);
      console.log(res);
      if (res.data && res.data.success === true) {
        this.setState({ dni: "", nombres: "", apellidos: "", id_cliente: 0 });
        this.stopCapturing();
        Swal.fire({
          titleText: "Se guardaron los cambios correctamente",
          icon: "success",
          timer: 2000,
        });
      } else {
        Swal.fire({
          titleText: "Error al guardar los datos",
          icon: "error",
          timer: 2000,
        });
      }
    } else {
      Swal.fire({
        title: "Hubo un error, verifique los datos (dni, nombres y apellidos)",
        icon: "error",
        timer: 2000,
      });
    }
  };
  searchDniCliente = async () => {
    console.log(this.state.dni);
    const dni = this.state.dni;
    if (dni.length < 8) {
      console.log("ingrese un dni valido a 8 caracteres");
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Ingrese un dni valido a 8 caracteres",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      this.setState({ loadingData: true });
      const res = await getDatapacienteByDni(dni);
      console.log("respuesta de dni", res);
      if (res.data.length !== 0) {
        this.setState({ loadingData: false });
        this.setState({
          nombres: res.data[0].nombres,
          apellidos: res.data[0].apellidos,
          id_cliente: res.data[0].id_cliente,
        });
      } else {
        Swal.fire({
          titleText:
            "No se encontraton datos del DNI, verifique que este registrado en el sistema!",
          icon: "error",
          timer: 3000,
        });
        this.setState({
          nombres: "",
          apellidos: "",
          id_cliente: 0,
          loadingData: false,
        });
      }
    }
  };

  onChangeDni = (e) => {
    // validacion solo acepta numeros
    const esValido = e.target.validity.valid;
    if (esValido) {
      this.setState({
        dni: e.target.value,
      });
    }
  };

  onImageDownload = async () => {
    if (
      localStorage.getItem("imageSrc") === "" ||
      localStorage.getItem("imageSrc") === null ||
      document.getElementById("imagediv").innerHTML === ""
    ) {
      //alert("No image to download");
      await this.setState({
        visible: true,
        alertColor: "danger",
        //alertMsg: res.mensaje,
        //loadindFingerprint: false,
      });
      document.getElementById("alertMsg").innerHTML =
        "<p>No hay imagen para descargar, verifique si capturó su huella digital, Gracias.</p>";
    } else {
      //alert(localStorage.getItem("imageSrc"));
      this.state.Fingerprint.stopCapture();
      downloadURI(localStorage.getItem("imageSrc"), "huella.png", "image/png");
    }
  };

  render() {
    const { deviceId } = this.state;

    const onDismiss = () => this.setState({ visible: false });

    const connected =
      deviceId !== "" && typeof deviceId !== "undefined" ? (
        <p className="text-success px-1">Conectado a {deviceId}</p>
      ) : (
        <p className="text-danger px-1">Desconectado</p>
      );

    return (
      <div className="App">
        <Container>
          <Row className="text-center">
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Label className="h1">LECTOR DE HUELLA DIGITAL</Label>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <br />
          <Row className="text-start">
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <div className="input-group mb-2">
                        <div className="input-group-prepend">
                          <div className="input-group-text">DNI</div>
                        </div>
                        <input
                          name="dni"
                          value={this.state.dni}
                          type="text"
                          className="form-control"
                          id="inlineFormInputGroup"
                          placeholder="Ingrese el dni ..."
                          onChange={this.onChangeDni}
                          maxLength="8"
                          pattern="[0-9]{0,13}"
                        />

                        <div className="input-group-prepend">
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={this.searchDniCliente}
                          >
                            <i className="fa fa-search" />
                          </button>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="input-group mb-2">
                        {this.state.loadingData === true ? (
                          <div>
                            <Spinner />
                          </div>
                        ) : (
                          <input
                            type="text"
                            className="form-control"
                            id="inlineFormInputGroup"
                            value={
                              this.state.nombres + " " + this.state.apellidos
                            }
                            disabled
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <Button
                        id="clear"
                        onClick={this.clearImage}
                        className="w-100 mb-2"
                        color="warning"
                        outline
                      >
                        Borrar Huella
                      </Button>
                      <Button
                        id="start"
                        onClick={this.startCapturing}
                        className="w-100 mb-2"
                        color="success"
                        outline
                      >
                        Comenzar Captura
                      </Button>
                      <Button
                        id="stop"
                        onClick={this.stopCapturing}
                        className="w-100 mb-2"
                        color="danger"
                        outline
                      >
                        Detener Captura
                      </Button>
                      <Button
                        id="getInfo"
                        onClick={this.getInfo}
                        className="w-100 mb-2"
                        color="info"
                        outline
                      >
                        Obtener dispositivos
                      </Button>
                      <Button
                        id="getState"
                        onClick={this.showState}
                        className="w-100 mb-2"
                        color="secondary"
                        outline
                      >
                        Mostrar State
                      </Button>
                    </Col>
                    <Col md={6}>
                      <Row className="text-center">
                        <div className="d-flex justify-content-center">
                          Estado: {connected}
                        </div>
                      </Row>
                      <Row>
                        {/* {this.state.loadindFingerprint === true ? (
                          <div className="text-center">
                            <div className="lds-spinner">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          </div>
                        ) : ( */}
                        <div className="text-center">
                          <div
                            id="imagediv"
                            style={{
                              width: "100%",
                            }}
                          ></div>

                          <Button
                            className="w-50"
                            size="sm"
                            onClick={this.verifyFingerPrintClient}
                          >
                            Guardar huella del cliente
                          </Button>
                        </div>
                        {/* )} */}
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <br />

          <Row>
            <Col>
              <Alert
                color={this.state.alertColor}
                isOpen={this.state.visible}
                toggle={onDismiss}
              >
                <p id="alertMsg"></p>
              </Alert>
            </Col>
          </Row>

          <br />
          <Row>
            <Col>
              <Button
                color="primary"
                className="form-control"
                id="saveImagePng"
                onClick={this.onImageDownload}
              >
                Exportar Imagen
              </Button>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <div id="devices-list"></div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default App;

function downloadURI(uri, name, dataURIType) {
  if (IeVersionInfo() > 0) {
    //alert("This is IE " + IeVersionInfo())
    const blob = dataURItoBlob(uri, dataURIType);
    window.navigator.msSaveOrOpenBlob(blob, name);
  } else {
    //alert("This is not IE.");
    let save = document.createElement("a");
    save.href = uri;
    save.download = name;
    let event = document.createEvent("MouseEvents");
    event.initMouseEvent(
      "click",
      true,
      false,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null
    );
    save.dispatchEvent(event);
  }
}

function dataURItoBlob(dataURI, dataURIType) {
  const binary = atob(dataURI.split(",")[1]);
  let array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: dataURIType });
}

function IeVersionInfo() {
  const sAgent = window.navigator.userAgent;
  const IEVersion = sAgent.indexOf("MSIE");

  // If IE, return version number.
  if (IEVersion > 0)
    return parseInt(
      sAgent.substring(IEVersion + 5, sAgent.indexOf(".", IEVersion)),
      10
    );
  // If IE 11 then look for Updated user agent string.
  else if (!!navigator.userAgent.match(/Trident\/7\./)) return 11;
  // Quick and dirty test for Microsoft Edge
  else if (document.documentMode || /Edge/.test(navigator.userAgent)) return 12;
  else return 0; //If not IE return 0
}
